import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

export default function Index() {
  const PageTitle = "Loading...";
  return (
    <Layout>
      <Helmet title={`${PageTitle} | ${config.siteTitle}`} />
      <div id="content">
        <div id="contentInner">
          <div id="contentWrapper">
            <h2>{PageTitle}</h2>
            <div className="divider" />
            <p>Loading site data</p>
          </div>
        </div>
        <div className="contentInnerBottom" />
        <div className="contentBottom" />
      </div>
    </Layout>
  );
}
